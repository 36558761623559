<template>
    <section>
      <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t('Global.Filters') }}
      </h3>
      <b-row>
        <b-col lg="2" md="3" sm="12">
          <label>
            {{ $t('Management.Processes.ProcessType') }}
          </label>
          <v-select
            v-model="serverParams.processType"
            :placeholder="$t('Global.Filter')"
            :options="ProcessTypes"
            @input="filterRows"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ $t(`Management.Processes.ProcessTypes.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Management.Processes.ProcessTypes.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="2" md="3" sm="12">
          <label>
            {{ $t('Management.Processes.HarvestYear') }}
          </label>
          <v-select
            v-model="serverParams.harvestYear"
            :placeholder="$t('Global.Filter')"
            :options="harvestYears"
            @input="filterRows"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ label }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ label }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="2" md="3" sm="12">
          <label>
            {{ $t('Management.Processes.Country') }}
          </label>
          <v-select
            v-model="serverParams.origin"
            :placeholder="$t('Global.Filter')"
            :options="areas"
            @input="filterRows"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ label }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ label }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="2" md="3" sm="12">
          <label>
            {{ $t('Management.Processes.RawHop') }}
          </label>
          <CurrencyInput
            v-model="rawHop"
            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
            :disabled="true"
            customClass="lg"
          />
        </b-col>
        <b-col lg="2" md="3" sm="12">
          <label>
            {{ $t('Management.Processes.RawHopZtr') }}
          </label>
          <CurrencyInput
            v-model="rawHopZtr"
            :options="{ currency: 'EUR', currencyDisplay: 'hidden', precision: 3, locale: 'de-DE' }"
            :disabled="true"
            customClass="lg"
          />
        </b-col>
      </b-row>
    </b-card>
      <b-card no-body>
        <div
          class="custom-search d-flex justify-content-between align-items-center mb-2 px-2 pt-2"
        >
          <h2 class="m-0 font-weight-bolder">
            {{ $t("Global.All") }} {{ $t("Management.Processes.Processes") }}
          </h2>
          <div class="d-flex">
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="serverParams.global"
                :placeholder="$t('Global.Search')"
                type="text"
                class="d-inline-block search"
                @input="filterRows"
              />
            </div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="ml-2 d-flex align-items-center"
              variant="primary"
              @click="$bvModal.show('process-modal')"
              v-if="$can($acl.action.Create, $acl.subjects.Process)"
            >
              <feather-icon class="mr-1" icon="AwardIcon" size="21" />
              {{ $tc('Global.New', 0) }} {{ $t('Management.Processes.Process') }}
            </b-button>
          </div>
        </div>
  
        <Table
          :rows="rowData"
          :search-term="searchTerm"
          :isLoadingExt="isLoading"
          :totalRecordsExt="totalRecords"
          @delete="deleteRow"
          @load="loadPaginatedProcesses"
        />
        <div :class="isLoading ? 'backdrop' : ''">
          <div :class="isLoading ? 'loading' : ''">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
          </div>
        </div>

        <b-modal
          id="process-modal"
          centered
          no-close-on-backdrop
          no-close-on-esc
          no-enforce-focus
          class="modal-dialog modal-dialog-centered"
        >
          <template #modal-title>
            {{ $t("Global.Select", {subject: $t('Management.Processes.ProcessType')}) }}
          </template>
          <template>
            <b-form-group :label="$t('Management.Processes.ProcessTypeSelection')">
              <b-form-radio-group
                v-model="selectedProcessType"
                :options="processTypeOptions"
                name="radio-inline"
              ></b-form-radio-group>
            </b-form-group>
          </template>
          <template #modal-footer>
            <div class="buttons-container d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create(selectedProcessType)"
              >
                {{ $t("Global.Accept") }}
              </b-button>
      
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('process-modal')"
              >
                {{ $t("Global.Retreat") }}
              </b-button>
            </div>
          </template>
        </b-modal>
      </b-card>
    </section>
  </template>
  <script>
  import { BFormInput, BCard, BButton, BModal, BFormGroup, BFormRadioGroup, BRow, BCol } from "bootstrap-vue";
  import deleteEntry from "@/utility/scripts/delete";
  import Table from "./components/table.vue";
  import vSelect from 'vue-select'
  import { ProcessTypes, ProcessTypesEnum } from "@/data/enums";
  import { weightFormatter } from '@/utility/format/formatter';
  import CurrencyInput from "@/views/miscellaneous/CurrencyInput.vue";


  export default {
    components: {
      BFormInput,
      BFormGroup,
      BFormRadioGroup,
      BCard,
      BModal,
      Table,
      BButton,
      BRow,
      BCol,
      vSelect,
      CurrencyInput,
    },
  
    data: () => ({
      filter: {
        processType: null,
        harvestYear: null,
        origin: null,
      },
      serverParams: {
        columnFilters: {
        },
        sort: [
          {
            field: 'harvestYear',
            type: 'asc'
          }
        ],
        page: 1, 
        perPage: 30,
        origin: '',
        processType: 'VA',
        harvestYear: new Date(Date.now()).getMonth() < 8 ? (new Date(Date.now()).getFullYear() - 1).toString() : new Date(Date.now()).getFullYear().toString(),
        global: '',
        rawHop: 0
      },
      isLoading: false,
      totalRecords: 0,
      searchTerm: "",
      rows: [],
      ProcessTypes,
      selectedProcessType: 'VA',
      harvestYears: [],
      areas: [],
      rawHop: 0,
      rawHopZtr: 0,
      weightFormatter,
      processTypeOptions: [
        { text: 'VA', value: 'VA'},
        { text: 'Leerung', value: 'Leerung' },
      ],
    }),

    computed: {
      rowData() {
        this.rawHop = 0;
        if(this.filter.processType && this.filter.harvestYear){
          this.rows.forEach(row => {
            if(row.processType === this.filter.processType && row.harvestYear === this.filter.harvestYear){
              this.rawHop += row.rawHop;
            }
          });
          this.rawHopZtr = (Number(this.rawHop) / 50);
        }
        return this.filterByProcessType(this.filterByHarvestYear(this.rows));
      },
    },
  
    created() {
      this.load();
    },
  
    methods: {

      filterRows(val){
        this.loadPaginatedProcesses(this.serverParams);
      },

      filterByProcessType(rows){
        return rows.filter(x => {
          if (this.filter.processType === null) return true
          return this.filter.processType === x.processType
        });
      },

      filterByHarvestYear(rows){
        return rows.filter(x => {
          if (this.filter.harvestYear === null) return true
          return this.filter.harvestYear === x.harvestYear
        });
      },

      async load() {
        try {
          this.loadPaginatedProcesses(this.serverParams);
          this.populateHarvestYears();
          this.getAllAreas();
        } catch (error) {
          console.error(error);
        }
      },

      async loadCount() {
        const { data } = await this.$axios.get("processes/process/count");
        this.totalRecords = data;
      },
      async loadCountFiltered(serverParams) {
        const { data } = await this.$axios.post("processes/process/countFiltered", serverParams);
        this.totalRecords = data;
      },
      async getAllAreas(){
        try{
          const { data } = await this.$axios.get(`countries`);
          data.forEach(country => {
            this.areas.push(country.name)
          })
        }catch (error) {
          this.$alert.error();
        }

      },
      async loadPaginatedProcesses(serverParams) {
        try{
          this.isLoading = true;
          serverParams.processType = this.serverParams.processType;
          serverParams.harvestYear = this.serverParams.harvestYear;
          serverParams.origin = this.serverParams.origin;
          serverParams.global = this.serverParams.global;
          if(this.serverParams.harvestYear === null){
            this.serverParams.harvestYear = '';
          }
          if(this.serverParams.processType === null){
            this.serverParams.processType = '';
          }
          if(this.serverParams.origin === null){
            this.serverParams.origin = '';
          }
          
          const { data } = await this.$axios.post('processes/paginatedProcesses', serverParams);
          this.isLoading = false;
          this.rows = data;
          // this.totalRecords = this.rows.length;
          if(this.serverParams.processType !== '' && this.serverParams.harvestYear !== ''){
            this.getAndCalculateRawHop(serverParams);
          }
          if(this.serverParams.processType !== '' || this.serverParams.harvestYear !== '' || this.serverParams.origin !== '' || serverParams.global !== ''){
            this.loadCountFiltered(serverParams);
          } else {
            this.loadCount();
          }
          
        } catch (error) {
          this.$alert.error();
        }
      },

      async getAndCalculateRawHop(serverParams){
        const { data } = await this.$axios.post('processes/process/getRawHop', serverParams);
        this.rawHop = data;
        this.rawHopZtr = (Number(this.rawHop) / 50);
      },

      addEntry(entry) {
        this.rows.push(entry);
      },

      populateHarvestYears() {
        let year = new Date(Date.now()).getFullYear();
        for (
          year;
          year >= 2006;
          year--
        ) {
          this.harvestYears.push(year.toString());
        }
      },

      async create(selectedProcessType) {
          this.$router.push({
          name: 'seals/processes-create',
          params: { selectedProcessType },
          })
      },
      async deleteRow(row) {
        try {
          await deleteEntry(this, {
            id: row.id,
            article: `${this.$t("Global.Articles_4")}`,
            individual: `"${row.processId}"`,
            subject: `${this.$t("Management.Processes.Process")}`,
            endpoint: "processes/",
          });
          this.load();
        } catch (error) {
          // continue regardless of error
        }
      },
    },
  };
  </script>
<style lang="scss">
  .rawHop{
    padding: 1em 0;
  }
  .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Hintergrundfarbe und Transparenz anpassen */
  z-index: 9999; /* Stelle sicher, dass der Backdrop über anderen Inhalten liegt */
}
</style>